// Entry point for the build script in your package.json
import Rails from '@rails/ujs';
import '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import 'form-request-submit-polyfill';
import { appsignal } from './appsignal';

Rails.start();
ActiveStorage.start();
import './controllers';
import 'trix';
import '@rails/actiontext';
import './richtext';
import { hotfixTurbo } from './turbo_hotfix';

hotfixTurbo();

addEventListener('trix-initialize', (event) => {
  const { toolbarElement } = event.target;
  const inputElement = toolbarElement.querySelector('input[name=href]');
  inputElement.type = 'text';
  inputElement.pattern = '(https?://|/).+';
});

addEventListener('turbo:before-stream-render', (event) => {
  const {
    detail: { newStream: newStream }
  } = event;

  if (!newStream) return;

  if (newStream.action != 'update') return;

  if (newStream.innerHTML.includes(`<turbo-frame id="${newStream.target}">`)) {
    const span = appsignal.createSpan((span) => {
      return span
        .setTags({
          target: newStream.target,
          action: newStream.action
        })
        .setError(new Error('Possible misuse of turbo'));
    });

    Promise.all([appsignal.send(span)]);
  }
});
