import { Controller } from '@hotwired/stimulus';
import select from 'tail.select';

export default class extends Controller {
  static targets = ['select'];
  static values = {
    multiple: { type: Boolean, default: true },
    placeholder: { type: String, default: 'Select an option' },
    multiShowCount: { type: Boolean, default: true },
    deselect: { type: Boolean, default: true },
    submitOnClose: { type: Boolean, default: false },
    clearKeysOnSubmit: { type: Array, default: [] }
  };

  connect() {
    let tailSelect = select(`#${this.selectTarget.id}`, {
      animate: true,
      multiple: this.multipleValue,
      multiLimit: Infinity,
      multiPinSelected: false,
      multiContainer: true,
      multiShowCount: this.multiShowCountValue,
      multiSelectGroup: true,
      deselect: this.deselectValue,
      search: true,
      placeholder: this.placeholderValue
    });

    if (this.submitOnCloseValue) {
      tailSelect.on('close', () => {
        this.submit();
      });
      tailSelect.on('change', (_item, state) => {
        let visible = !!tailSelect.dropdown.style.display;
        if (state == 'unselect' && !visible) {
          this.submit();
        }
      });
    }
  }

  submit = () => {
    this.clearKeysOnSubmitValue.forEach((key) => {
      try {
        this.selectTarget.form.elements[key].remove();
      } catch (error) {
        console.error(error);
      }
    });
    this.selectTarget.form.submit();
  };
}
