import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['geocoder', 'longitude', 'latitude', 'place'];
  static values = {
    apiKey: String,
    countries: String,
    types: String,
    initialInput: String,
    locale: String
  };

  async connect() {
    this.mapboxGeocoderClass = (await import('@mapbox/mapbox-gl-geocoder')).default;
    this.geocoder();
  }

  clearHiddenFields() {
    if (this.hasLongitudeTarget) this.longitudeTarget.value = '';
    if (this.hasLatitudeTarget) this.latitudeTarget.value = '';
    if (this.hasPlaceTarget) this.placeTarget.value = '';
    this.geocoder().setPlaceholder(window.I18n.search);
    this.dispatchClearEvent();
  }

  dispatchClearEvent() {
    const event = new CustomEvent('autocompletePlacesClear');
    window.dispatchEvent(event);
  }

  dispatchResultEvent() {
    const event = new CustomEvent('autocompletePlacesResult');
    window.dispatchEvent(event);
  }

  geocoder() {
    let skipFirstResult = this.hasInitialInputValue;
    if (this._geocoder === undefined) {
      this._geocoder = new this.mapboxGeocoderClass({
        accessToken: this.apiKeyValue,
        countries: this.hasCountriesValue ? this.countriesValue : 'de,at,nl',
        language: this.localeValue,
        types: this.hasTypesValue ? this.typesValue : undefined,
        placeholder: window.I18n.search
      });
      this._geocoder.addTo(this.geocoderTarget);
      this._geocoder.setInput(this.hasInitialInputValue ? this.initialInputValue : '');
      this._geocoder.on('results', (e) => {
        // This is a bit hacky, but I found no other way to simply hide the results initially
        // If there is an initialValue passed to this controller, we prefill the input
        // This way the user is not required to enter the location again if he wants to change only other values in a form
        // If however the user touches the input this is ignored and he can procede as usual
        if (skipFirstResult) {
          skipFirstResult = false;
          e.features = [];
        }
      });
      this._geocoder.on('clear', () => this.clearHiddenFields());
      this._geocoder.on('loading', () => this.clearHiddenFields());
      this._geocoder.on('result', (e) => {
        if (this.hasLongitudeTarget) this.longitudeTarget.value = e.result.center[0];
        if (this.hasLatitudeTarget) this.latitudeTarget.value = e.result.center[1];
        if (this.hasPlaceTarget) this.placeTarget.value = e.result.place_name;
        if (this.element.nodeName == 'FORM') {
          this.element.requestSubmit();
        }
        this.dispatchResultEvent();
      });
    }
    return this._geocoder;
  }
}
