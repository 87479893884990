import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['fileListContainer'];

  connect() {
    this.checkForMissingFileListContainer();
    this.initListeners();
  }

  checkForMissingFileListContainer() {
    if (!this.hasFileListContainerTarget) {
      console.error('A file-list-container target is needed for direct uploads.');
    }
  }

  initListeners() {
    this.element.addEventListener('direct-upload:initialize', (event) =>
      this.initializeUpload(event.detail.id, event.detail.file.name)
    );
    this.element.addEventListener('direct-upload:start', (event) =>
      this.start(event.detail.id)
    );
    this.element.addEventListener('direct-upload:progress', (event) =>
      this.progress(event.detail)
    );
    this.element.addEventListener('direct-upload:error', (event) => this.error(event));
    this.element.addEventListener('direct-upload:end', (event) =>
      this.end(event.detail.id)
    );
  }

  initializeUpload(id, filename) {
    this.fileListContainerTarget.insertAdjacentHTML(
      'beforeend',
      `
        <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
          <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
          <span class="direct-upload__filename">${filename}</span>
        </div>
      `
    );
  }

  start(id) {
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.remove('direct-upload--pending');
  }

  progress(detail) {
    const { id, progress } = detail;
    const progressElement = document.getElementById(`direct-upload-progress-${id}`);
    progressElement.style.width = `${progress}%`;
  }

  error(event) {
    event.preventDefault();
    const { id, error } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--error');
    element.setAttribute('title', error);
  }

  end(id) {
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--complete');
  }

  updatePreview(event) {
    if (event.target.multiple) {
      return;
    }

    event.target.parentNode.classList.add('direct-upload-finished');
  }
}
