import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';
export default class extends Controller {
  static targets = ['button', 'menu'];
  static values = {
    open: false
  };

  connect() {
    useClickOutside(this);
    const toggleFocusInside = this.toggleFocusInside.bind(this);
    toggleFocusInside(false);

    this.buttonTarget.addEventListener('click', () => {
      this.setMenuOpen(!this.openValue);
    });
  }

  clickOutside() {
    this.setMenuOpen(false);
  }

  close(event) {
    event.preventDefault();
    this.setMenuOpen(false);
  }

  setMenuOpen(open) {
    this.openValue = open;
    const closeViaEsc = this.closeViaEsc.bind(this);
    if (open) {
      this.menuTarget.classList.remove('visually-hidden');
      this.element.addEventListener('keydown', closeViaEsc);
    } else {
      this.menuTarget.classList.add('visually-hidden');
      this.element.removeEventListener('keydown', closeViaEsc);
    }
    this.buttonTarget.setAttribute('aria-expanded', JSON.stringify(open));
    this.toggleFocusInside(open);
  }

  toggleFocusInside(isFocusable) {
    const focusableChildren = this.menuTarget.querySelectorAll(
      'a, button, input, select, textarea'
    );

    focusableChildren.forEach((child) => {
      child.setAttribute('tabindex', isFocusable ? '0' : '-1');
    });
  }

  closeViaEsc(e) {
    if (e.keyCode === 27) {
      this.buttonTarget.click();
    }
  }
}
