import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ['panel', 'outside', 'open'];

  connect() {
    if (this.hasOutsideTarget) useClickOutside(this, { element: this.outsideTarget });
    if (this.hasPanelTarget) this.panelTarget.hidden = true;
  }

  open() {
    this.panelTarget.hidden = false;
    const mainSection = document.querySelector('.staff-layout__main-section');
    mainSection &&
      mainSection.classList.add('staff-layout__main-section--with-side-panel');
  }

  clickOutside(event) {
    if (this.openTarget.contains(event.target)) {
      return;
    }
    this.close();
  }

  close() {
    this.panelTarget.hidden = true;
    const mainSection = document.querySelector('.staff-layout__main-section');
    mainSection &&
      mainSection.classList.remove('staff-layout__main-section--with-side-panel');
  }

  toggle() {
    this.panelTarget.style.display === 'block' ? this.close() : this.open();
  }
}
