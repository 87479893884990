import { Controller } from '@hotwired/stimulus';

const defaultColor = '#3FB1CE';
const selectedColor = '#CE8C40';
const locationColor = '#CE4053';

export default class extends Controller {
  static values = {
    apiKey: String,
    studios: Array,
    location: Object,
    selectedId: Number,
    viewPortKey: String
  };

  static targets = ['map', 'mapToggleButton'];

  async connect() {
    await this.initMapbox();
  }

  select(id) {
    if (id === this.selectedIdValue) return;

    if (this.selectedIdValue !== 0) {
      this.changeMarkerColor(this.selectedIdValue, defaultColor);
    }
    this.selectedIdValue = id;
    this.changeMarkerColor(id, selectedColor);
  }

  onSelectRadio(e) {
    const selectedId = parseInt(e.target.value);
    this.select(selectedId);
    if (this.markersMap().has(selectedId)) {
      const marker = this.markersMap().get(selectedId);
      this.map().flyTo({ center: marker.getLngLat(), zoom: 13 });
    }
  }

  onSelectMarker(id) {
    const radio_button = document.getElementById(`carts_studio_form_studio_id_${id}`);
    radio_button.checked = true;
    this.select(id);
    this.toggleRadios();
  }

  onMapToggle() {
    const visibleClass = 'map-shown';
    this.element.classList.toggle(visibleClass);

    const isHidden = !this.element.classList.contains(visibleClass);
    this.mapToggleButtonTarget.innerText =
      this.mapToggleButtonTarget.dataset[isHidden ? 'text' : 'toggledText'];

    this.toggleRadios();
  }

  toggleRadios() {
    const visibleClass = 'map-shown';
    const isHidden = !this.element.classList.contains(visibleClass);
    const hasCheckedRadio = !!document.querySelector('.booking-radio input:checked');

    if (hasCheckedRadio) {
      const radioToggles = this.element.querySelectorAll('.booking-radio input');

      radioToggles.forEach((el) => {
        el.closest('span').style.display = isHidden ? 'block' : 'none';

        if (+el.value === this.selectedIdValue) {
          el.closest('span').style.display = 'block';
        }
      });
    }
  }

  changeMarkerColor(id, color) {
    if (this.markersMap().has(id)) {
      const marker = this.markersMap().get(id);
      const lngLat = marker.getLngLat();
      marker.remove();
      this.createMarker(lngLat, { color: color, id: id });
    }
  }

  createMarker(lngLat, options = { color: any, id: any }) {
    let newMarker;
    if (options.color === undefined) {
      newMarker = new window.mapboxGl.Marker();
    } else {
      newMarker = new window.mapboxGl.Marker({ color: options.color });
    }
    newMarker.setLngLat(lngLat).addTo(this.map());
    if (options.id !== undefined) {
      newMarker
        .getElement()
        .addEventListener('click', () => this.onSelectMarker(options.id));
      newMarker.getElement().style.cursor = 'pointer';
      this.markersMap().set(options.id, newMarker);
    }
  }

  async locationValueChanged() {
    await this.initMapbox();
    if (this.locationValue.latitude !== null && this.locationValue.longitude !== null) {
      const lngLat = [this.locationValue.longitude, this.locationValue.latitude];
      this.createMarker(lngLat, { color: locationColor });
      this.showMap();
    }
  }

  async studiosValueChanged() {
    await this.initMapbox();
    const lngLatBounds = new window.mapboxGl.LngLatBounds();
    this.markersMap().clear();
    this.studiosValue.forEach((marker) => {
      this.createMarker([marker.longitude, marker.latitude], {
        id: marker.id,
        color: marker.id === this.selectedIdValue ? selectedColor : defaultColor
      });
      lngLatBounds.extend([marker.longitude, marker.latitude]);
    });
    this.fitBounds(lngLatBounds, 12, false);
    if (this.markersMap().size > 0) {
      this.showMap();
    }
  }

  fitBounds(lngLatBounds, maxZoom, animate) {
    if (!lngLatBounds.isEmpty()) {
      this.map().fitBounds([lngLatBounds.getSouthWest(), lngLatBounds.getNorthEast()], {
        padding: { top: 40, bottom: 10, left: 15, right: 15 },
        maxZoom: maxZoom,
        animate: animate
      });
    }
  }

  async initMapbox() {
    if (window.mapboxGl == undefined) {
      window.mapboxGl = await import('mapbox-gl');
    }
    this.map();
  }

  showMap() {
    this.map().resize();
  }

  markersMap() {
    if (this._markers === undefined) {
      this._markers = new Map();
    }
    return this._markers;
  }

  map() {
    const { center, zoom } = this.initialViewPort();

    if (this._map === undefined) {
      this._map = new window.mapboxGl.Map({
        accessToken: this.apiKeyValue,
        container: 'map', // container ID
        style: 'mapbox://styles/mapbox/streets-v11', // style URL
        center: center,
        zoom: zoom
      });
    }
    return this._map;
  }

  initialViewPort() {
    if (this._initialViewPort === undefined) {
      const viewports = this.constructor.viewports;
      this._initialViewPort = viewports[this.viewPortKeyValue] || viewports.fallback;
    }

    return this._initialViewPort;
  }

  // center is [lng, lat]
  static viewports = {
    de: { center: [10, 51.13], zoom: 4.6 },
    at: { center: [14.35, 47.6], zoom: 5.5 },
    nl: { center: [5.6, 51.96], zoom: 5.8 },
    fallback: { center: [7.217, 51.478], zoom: 9 }
  };
}
