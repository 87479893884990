import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'calendar', 'draggableEl'];
  static values = {
    shootingsUrl: String,
    appointmentsUrl: String,
    locale: String
  };

  connect() {}

  async connect() {
    if (this.hasCalendarTarget) {
      const { makeCalendar } = await import('../calendar_controller_helper');
      this.calendar = makeCalendar({
        locale: this.localeValue,
        calendarTarget: this.calendarTarget,
        draggableElements: this.draggableElTargets,
        eventSources: [
          { url: this.shootingsUrlValue },
          { url: this.appointmentsUrlValue, color: '#d88737' }
        ],
        eventDrop: this.eventDrop,
        drop: this.drop
      });
      this.calendar.render();
    }
  }

  inputChanged(event) {
    if (this.newShootingEvent === undefined) {
      this.createNewShootingEvent(
        JSON.parse(this.draggableElTarget.dataset.event),
        event.target.value,
        this.draggableElTarget
      );
    } else {
      this.newShootingEvent.setStart(event.target.value, { maintainDuration: true });
    }

    if (!this.visible(new Date(Date.parse(event.target.value)))) {
      this.calendar.gotoDate(event.target.value);
    }
    this.calendar.scrollToTime(event.target.value.substring(11, 16));
  }

  visible(date) {
    return date > this.calendar.view.currentStart && date < this.calendar.view.currentEnd;
  }

  eventDrop = (eventDropInfo) => {
    this.setInput(eventDropInfo.event.startStr);
  };

  drop = (dropInfo) => {
    this.setInput(dropInfo.dateStr);
    this.createNewShootingEvent(
      JSON.parse(dropInfo.draggedEl.dataset.event),
      dropInfo.dateStr,
      dropInfo.draggedEl
    );
  };

  createNewShootingEvent(event, start, draggedEl) {
    this.newShootingEvent = this.calendar.addEvent({
      ...event,
      start: Date.parse(start),
      end: Date.parse(start) + event.duration
    });
    draggedEl.remove();
  }

  setInput(datetime) {
    this.inputTarget.value = datetime.substr(0, 19);
  }
}
