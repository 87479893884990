import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'input'];
  static values = { inverted: Boolean, hide: Boolean };

  connect() {
    if (!this.hasCheckboxTarget) {
      console.warn('This Controller needs a checkbox target');
      return;
    }
    this.setDisabled(!this.checkboxTarget.checked);
    this.checkboxTarget.addEventListener('change', (event) =>
      this.setDisabled(!event.target.checked)
    );
  }

  setDisabled(value) {
    this.inputTargets.forEach((input) => {
      this.invertedValue ? (input.disabled = !value) : (input.disabled = value);
      if (this.hideValue) {
        value
          ? input.parentElement.classList.add('visually-hidden')
          : input.parentElement.classList.remove('visually-hidden');
      }
    });
  }
}
