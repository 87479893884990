import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    products: Array,
    subtotalAmount: String,
    bookingNumber: String,
    voucherCode: String
  };

  connect() {
    const emid = this.getCookie('emid');

    // AWIN conversionTag
    /*** Do not change ***/
    var AWIN = {};
    AWIN.Tracking = {};
    AWIN.Tracking.Sale = {};
    /*** Set your transaction parameters ***/
    AWIN.Tracking.Sale.amount = this.subtotalAmountValue;
    AWIN.Tracking.Sale.channel = 'aw';
    AWIN.Tracking.Sale.orderRef = this.bookingNumberValue;
    AWIN.Tracking.Sale.parts = `DEFAULT:${this.subtotalAmountValue}`;
    AWIN.Tracking.Sale.currency = 'EUR';
    AWIN.Tracking.Sale.voucher = this.voucherCodeValue;
    AWIN.Tracking.Sale.test = !!emid ? '0' : '1';
    // This triggers an GTM event to load the awin master tag.
    window.dataLayer && window.dataLayer.push({ event: 'payment:success' });

    const script = document.createElement('script');
    script.src = 'https://trck.picturepeople.de/trck/ebasket/ebasket.js';
    document.body.append(script);

    script.addEventListener('load', () => {
      if (!emid) return;

      this.productsValue.forEach((obj) => {
        eamTrckAddBasketItem(
          obj.campaign_id,
          obj.token,
          obj.trigger_id,
          obj.article_number,
          obj.amount,
          obj.price,
          emid,
          obj.product_name,
          obj.category,
          obj.custom
        );
      });

      eamTrckSubmitBasket();
    });
  }

  getCookie = (name) => {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  };
}
