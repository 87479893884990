import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="addon"
export default class extends Controller {
  static targets = ['decreaseButton', 'increaseButton', 'input'];

  connect() {
    if (this.hasDecreaseButtonTarget) {
      this.toggleDecreaseButtonDisabledState();
    }
  }

  toggleDecreaseButtonDisabledState() {
    this.decreaseButtonTarget.disabled = +this.inputTarget.value <= 0;
  }

  onValueChange() {
    if (+this.inputTarget.value < 0) {
      this.inputTarget.value = 0;
    }
    this.toggleDecreaseButtonDisabledState();
  }

  decreaseValue() {
    this.inputTarget.value = +this.inputTarget.value - 1;
    this.toggleDecreaseButtonDisabledState();
  }

  increaseValue() {
    this.inputTarget.value = +this.inputTarget.value + 1;
    this.toggleDecreaseButtonDisabledState();
  }
}
