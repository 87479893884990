import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';
import { patch } from '@rails/request.js';

export default class extends Controller {
  static values = {
    resourceName: String,
    paramName: {
      type: String,
      default: 'position'
    },
    responseKind: {
      type: String,
      default: 'html'
    },
    animation: Number,
    handle: Boolean,
    groupName: String,
    groupParamName: String,
    groupParamValue: Number,
    put: Boolean,
    putGroups: Array
  };

  initialize() {
    this.end = this.end;
    this.element[this.identifier] = this;
  }

  connect() {
    this.sortable = new Sortable(this.element, {
      ...this.defaultOptions,
      ...this.options,
      ...this.groupOptions
    });
  }

  disconnect() {}

  end = async ({ item, newIndex, to }) => {
    if (!item.dataset.sortableUpdateUrl) return;

    const param = this.resourceNameValue
      ? `${this.resourceNameValue}[${this.paramNameValue}]`
      : this.paramNameValue;

    const data = new FormData();
    data.append(param, newIndex + 1);
    if (to && to[this.identifier].groupNameValue) {
      data.append(
        to[this.identifier].groupParamNameValue,
        to[this.identifier].groupParamValueValue
      );
    }

    await patch(item.dataset.sortableUpdateUrl, {
      body: data,
      responseKind: this.responseKindValue
    });
  };

  get options() {
    return {
      animation: this.animationValue || this.defaultOptions.animation || 150,
      handle: this.handleValue && '.handle',
      onEnd: this.end
    };
  }

  get defaultOptions() {
    return {};
  }

  get groupOptions() {
    return {
      group: {
        name: this.groupNameValue || 'default',
        put: this.putValue || this.putGroupsValue,
        pull: this.hasGroupNameValue
      }
    };
  }
}
