import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="login"
export default class extends Controller {
  static targets = ['POSLoginForm', 'POSPin', 'POSHiddenEmail', 'userSelectButton'];

  connect() {}

  onUserSelect(e) {
    const { target } = e;
    const email = target.dataset.email;
    target.dataset.variant = 'toggle-primary';

    const otherTargets = this.userSelectButtonTargets.filter((t) => t !== target);
    otherTargets.forEach((t) => {
      t.dataset.variant = 'toggle';
    });

    this.POSHiddenEmailTarget.value = email;
    this.POSLoginFormTarget.hidden === true && (this.POSLoginFormTarget.hidden = false);
    this.POSPinTarget.focus();
  }
}
