import { Controller } from '@hotwired/stimulus';
require('sortable-tablesort');

export default class extends Controller {
  connect() {
    // I did not find another way to initilize the sortable table
    // thus this controller needs to be registered on the table element
    // then we add the sortable class to the table element
    this.element.classList.add('sortable');
  }
}
