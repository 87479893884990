import { autofill } from '@mapbox/search-js-web';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    apiKey: String,
    country: String,
    locale: String
  };

  static targets = ['addressInput'];

  connect() {
    autofill({
      accessToken: this.apiKeyValue,
      options: { country: this.countryValue, language: this.localeValue }
    });

    if (this.hasAddressInputTarget) {
      // This is a workaround for a misbehaving JS.
      // autofill seems to attach ` address-search` to the name of the street field,
      // which disturbs the way rails interprets form parameters (and isn't good praktice after all).
      // But reverting the name back to its original doesn't seem to break anything, so wie just do that.
      this.addressInputTarget.name = this.addressInputTarget.name.split(' ')[0];
    }
  }
}
