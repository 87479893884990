import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="kpis-fullscreen"
export default class extends Controller {
  static targets = ['content'];
  static values = {
    fullscreen: { type: Boolean, default: false }
  };

  connect() {}

  toggleFullscreen() {
    this.contentTarget.classList.toggle('kpis-layout__content--fullscreen');
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('fullscreen', !this.fullscreenValue);
    window.location.search = urlParams;
  }
}
