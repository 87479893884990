import { Controller } from '@hotwired/stimulus';
import { loadScript } from '@paypal/paypal-js';
import { patch } from '@rails/request.js';

export default class extends Controller {
  static values = {
    clientId: String,
    locale: String,
    amount: String,
    onCancelUrl: String,
    onErrorUrl: String,
    prepareUrl: String,
    completeUrl: String,
    handlePaidUrl: String,
    bookingNumber: String
  };

  async connect() {
    let locale;
    switch (this.localeValue) {
      case 'de':
        locale = 'de_DE';
        break;
      case 'en':
        locale = 'en_US';
        break;
      case 'nl':
        locale = 'nl_NL';
        break;
    }
    try {
      const nlOptions = this.localeValue === 'nl' ? { 'enable-funding': ['ideal'] } : {};
      paypal = await loadScript({
        'client-id': this.clientIdValue,
        currency: 'EUR',
        intent: 'capture',
        locale: locale,
        ...nlOptions
      });
    } catch (error) {
      console.error('failed to load the PayPal JS SDK script', error);
    }

    if (paypal) {
      try {
        await paypal
          .Buttons({
            createOrder: async (data, actions) => {
              let orderId = await actions.order.create({
                purchase_units: [
                  {
                    reference_id: this.bookingNumberValue,
                    custom_id: this.bookingNumberValue,
                    invoice_id: this.bookingNumberValue,
                    amount: {
                      value: this.amountValue
                    }
                  }
                ]
              });

              const response = await this.handlePatch(
                this.prepareUrlValue,
                { paypal_order_id: orderId },
                'application/json'
              );

              localStorage.setItem('orderEventId', response.event_id);

              return orderId;
            },
            onCancel: async () => {
              await this.handlePatch(
                this.onCancelUrlValue,
                { event_id: this.eventId() },
                'application/json'
              );

              return true;
            },
            onError: async () => {
              await this.handlePatch(
                this.onErrorUrlValue,
                { event_id: this.eventId() },
                'application/json'
              );
            },
            // Finalize the transaction after payer approval
            onApprove: async (data, actions) => {
              const completeResponse = await this.handlePatch(
                this.completeUrlValue,
                { paypal_order_id: data.orderID, event_id: this.eventId() },
                'application/json'
              );

              if (!completeResponse.success) throw 'Payment not completed';

              await this.handlePatch(this.handlePaidUrlValue, {}, 'turbo-stream');
            }
          })
          .render(`#${this.element.id}`);
      } catch (error) {
        console.error('failed to render the PayPal Buttons', error);
      }
    }
  }

  handlePatch = async (url, body, responseKind) => {
    const response = await patch(url, { body, responseKind });

    if (response.statusCode == 409) {
      location.reload();
    }

    if (response.ok && responseKind === 'application/json') {
      return await response.json;
    }
  };

  eventId = () => {
    return localStorage.getItem('orderEventId');
  };
}
