import Trix from 'trix';

addHeadingAttributes();

addEventListener('trix-initialize', function (event) {
  new RichText(event.target);
});

class RichText {
  constructor(element) {
    this.element = element;
    this.insertHeadingElements();
    this.addTextAlignCenterButtonInToolbar();
  }

  insertHeadingElements() {
    this.removeOriginalHeadingButton();
    this.insertNewHeadingButtons();
  }

  addTextAlignCenterButtonInToolbar() {
    Trix.config.blockAttributes.textAlignCenter = {
      tagName: 'centered-div'
    };

    this.buttonGroupBlockTools.insertAdjacentHTML('beforeend', this.textAlignButtons);
  }

  removeOriginalHeadingButton() {
    this.buttonGroupBlockTools.removeChild(this.originalHeadingButton);
  }

  insertNewHeadingButtons() {
    this.buttonGroupBlockTools.insertAdjacentHTML(
      'afterbegin',
      this.headingButtonsTemplate
    );
  }

  get buttonGroupBlockTools() {
    return this.toolbarElement.querySelector('[data-trix-button-group=block-tools]');
  }

  get originalHeadingButton() {
    return this.toolbarElement.querySelector('[data-trix-attribute=heading1]');
  }

  get toolbarElement() {
    return this.element.toolbarElement;
  }

  get headingButtonsTemplate() {
    return '<button type="button" class="trix-button" data-trix-attribute="heading1" title="Heading 1" tabindex="-1">H1</button><button type="button" class="trix-button" data-trix-attribute="heading2" title="Heading 2" tabindex="-1">H2</button><button type="button" class="trix-button" data-trix-attribute="heading3" title="Heading 3" tabindex="-1">H3</button><button type="button" class="trix-button" data-trix-attribute="heading4" title="Heading 4" tabindex="-1">H4</button><button type="button" class="trix-button" data-trix-attribute="heading5" title="Heading 5" tabindex="-1">H5</button><button type="button" class="trix-button" data-trix-attribute="heading6" title="Heading 6" tabindex="-1">H6</button>';
  }

  get textAlignButtons() {
    return `<button type="button" class="trix-button" data-trix-attribute="textAlignCenter">
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 6h8M6 10h12M8 14h8M6 18h12"/>
              </svg>
            </button>`;
  }
}

function addHeadingAttributes() {
  Array.from(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).forEach((tagName, i) => {
    Trix.config.blockAttributes[`heading${i + 1}`] = {
      tagName: tagName,
      terminal: true,
      breakOnReturn: true,
      group: false
    };
  });
}
