import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['SubNav', 'main'];

  initialize() {
    const navWidth = getComputedStyle(this.mainTarget).getPropertyValue('--nav-width');
    const navTotal = getComputedStyle(this.mainTarget).getPropertyValue(
      '--nav-total-desktop'
    );

    if (this.hasSubNavTarget) {
      this.mainTarget.style.setProperty('--nav-total-desktop', navTotal);
    } else {
      this.mainTarget.style.setProperty('--nav-total-desktop', navWidth);
    }
  }
}
