import { Controller } from '@hotwired/stimulus';
import { WidgetInstance } from 'friendly-challenge';

export default class extends Controller {
  static targets = ['submit', 'captcha'];
  static values = {
    sitekey: String,
    locale: String
  };

  connect() {
    const options = {
      doneCallback: () => {
        this.submitTarget.disabled = false;
      },
      sitekey: this.sitekeyValue,
      language: this.localeValue,
      startMode: 'focus'
    };

    const widget = new WidgetInstance(this.captchaTarget, options);
  }
}
