import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="geolocation"
export default class extends Controller {
  static targets = ['longitude', 'latitude'];

  connect() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) =>
        this.updateLocation(position)
      );
    }
  }

  updateLocation(position) {
    this.longitudeTarget.value = position.coords.longitude;
    this.latitudeTarget.value = position.coords.latitude;
    if (this.element.nodeName == 'FORM') {
      this.element.requestSubmit();
    }
  }
}
