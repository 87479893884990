import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { replace: Boolean };

  async connect() {
    const { useMutation } = await import('stimulus-use');
    useMutation(this, { attributes: true });
  }

  mutate(entries) {
    entries.forEach((mutation) => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'src') {
        const src = this.element.getAttribute('src');
        if (src != null) {
          const newUrl = new URL(src);
          const oldUrl = new URL(location.href);
          if (newUrl.pathname === oldUrl.pathname) {
            oldUrl.searchParams.forEach((value, name) => {
              if (!newUrl.searchParams.has(name)) {
                newUrl.searchParams.append(name, value);
              }
            });
          }
          this.replaceValue
            ? Turbo.navigator.history.replace(newUrl)
            : Turbo.navigator.history.push(newUrl);
        }
      }
    });
  }
}
