import CheckboxSelectAll from 'stimulus-checkbox-select-all';
export default class extends CheckboxSelectAll {
  static targets = ['text', 'checkbox'];

  connect() {
    super.connect();

    this.updateCounter();
  }

  updateCounter() {
    let counter;
    counter = this.checkboxTargets.filter((x) => x.checked).length;
    this.textTarget.innerHTML =
      counter == 1
        ? `${counter} ${window.I18n.image_counter.one}`
        : `${counter} ${window.I18n.image_counter.other}`;
  }
}
