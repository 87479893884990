import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['submit', 'counter'];
  maxFilesValue = 10;

  async connect() {
    const { useMutation } = await import('stimulus-use');
    this.updateSubmit(this.counterTarget.children[0].children[0].value);
    useMutation(this, {
      element: this.counterTarget,
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true
    });
  }

  mutate(entries) {
    switch (entries[0].type) {
      case 'attributes':
        this.updateSubmit(entries[0].target.value);
        break;

      case 'childList':
        this.updateSubmit(entries[0].target.children[0].children[0].value);
        break;

      default:
        break;
    }
  }

  updateSubmit(value) {
    if (value == this.maxFilesValue) {
      this.submitTarget.disabled = false;
      this.submitTarget.value = 'Abschicken';
    } else if (value < this.maxFilesValue) {
      this.submitTarget.disabled = true;
      const left = this.maxFilesValue - value;
      this.submitTarget.value = `Bitte lade noch ${left} Photos hoch`;
    } else if (value > this.maxFilesValue) {
      this.submitTarget.disabled = true;
      const over = value - this.maxFilesValue;
      this.submitTarget.value = `Bitte entferne ${over} Photos`;
    }
  }
}
