// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr';

// you can also import a translation file
import { German } from 'flatpickr/dist/l10n/de.js';

import { format, isSameDay, isSameMonth, isSameYear } from 'date-fns';

// import a theme (could be in your main CSS entry too...)
// import 'flatpickr/dist/themes/dark.css';

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  static targets = ['dateFrom', 'dateTo'];
  static values = {
    preset: String,
    seperator: ' - '
  };

  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: {
        ...German,
        rangeSeparator: this.seperatorValue
      },
      weekNumbers: true,
      defaultDate: [this.from(), this.to()]
    };
  }

  from() {
    return this.dateFromTarget.value;
  }

  to() {
    return this.dateToTarget.value;
  }

  formatDate(selectedDates) {
    let [date_from, date_to] = selectedDates.map((date) => new Date(date));
    const full_date = (date) => format(date, 'dd.MM.yyyy');

    switch (this.presetValue) {
      case 'day':
        return full_date(date_from);

      case 'week':
        return (
          'KW ' +
          format(date_from, 'I (dd.MM.') +
          this.seperatorValue +
          full_date(date_to) +
          ')'
        );

      case 'month':
        return format(date_from, 'MM.yyyy');

      case 'quarter':
        return 'Q' + format(date_from, 'Q (MM-') + format(date_to, 'MM.yyyy') + ')';

      case 'year':
        return format(date_from, 'yyyy');

      default: // custom
        let date_format = isSameDay(date_from, date_to)
          ? null
          : isSameMonth(date_from, date_to)
          ? 'dd'
          : isSameYear(date_from, date_to)
          ? 'dd.MM.'
          : 'dd.MM.yyyy';

        return (
          (date_format ? format(date_from, date_format) + this.seperatorValue : '') +
          full_date(date_to)
        );
    }
  }

  setPresetPickerToCustom() {
    // switch preset to custom if it's not a known preset
    let select = document.querySelector('#select-date-preset');
    select.selectedIndex = select.options.length - 1;
  }

  ready(selectedDates, _dateStr, _instance) {
    // happens automatically if text_field but not if label
    this.instanceTarget.innerHTML = this.formatDate(selectedDates);
  }

  valueUpdate(selectedDates, dateStr, _instance) {
    // this is just for the label in view and does not manipulate the flatpickr value itself
    this.instanceTarget.innerHTML = this.formatDate(selectedDates);

    let [date_from, date_to] = dateStr.split(this.seperatorValue);

    // set default date_to to date_from if it's a single day
    date_to = date_to || date_from;
    this.dateFromTarget.value = date_from;
    this.dateToTarget.value = date_to;
  }

  close() {
    this.setPresetPickerToCustom();

    // access the form via hidden input because instanceTarget is a label which doesn't submit the form
    this.dateFromTarget.form.submit();
  }
}
