import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const emid = this.getCookie('emid');
    if (emid) {
      const script = document.createElement('script');
      script.src = `https://trck.picturepeople.de/trck/etp/?campaign_id=1&type=basketfreeze&emid=${emid}`;
      document.body.append(script);
    }
  }

  getCookie = (name) => {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  };
}
