import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggle', 'hide'];

  connect() {
    if (!this.hasToggleTarget) {
      console.warn('This Controller needs a toggle target');
      return;
    }

    this.toggleTargets.forEach((toggleTarget) => {
      if (toggleTarget.checked) {
        this.toggleVisibility(toggleTarget.value);
      }
      toggleTarget.addEventListener('change', (event) => {
        this.toggleVisibility(event.target.value);
      });
    });
  }

  toggleVisibility(targetValue) {
    this.hideTargets.forEach((hideTarget) => {
      hideTarget.hidden = hideTarget.dataset.toggleVisibilityKeyValue !== targetValue;
      hideTarget.classList.toggle('hidden');
    });
  }
}
