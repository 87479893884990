import { getMetaValue } from './get_meta_value';
import { fetchJSON } from './fetch_json';

/**
 * @param {'GET' | 'POST' | 'PATCH' | 'DELETE'} method
 * @param {string} url
 * @param {any} [body]
 */
export const request = (method, url, body) =>
  fetchJSON(url, {
    method,
    headers: {
      'X-CSRF-Token': getMetaValue('csrf-token'),
      'Content-Type': 'application/json'
    },
    body: body ? JSON.stringify(body) : null
  });

/**
 * @param {string} url
 */
export const get = (url) => request('GET', url);
/**
 * @param {string} url
 * @param {any} body
 */
export const post = (url, body) => {
  request('POST', url, body);
};
/**
 * @param {string} url
 * @param {any} body
 */
export const patch = (url, body) => request('PATCH', url, body);
/**
 * @param {string} url
 */
export const deleteRequest = (url) => request('DELETE', url);
