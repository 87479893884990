import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'grid',
    'option',
    'switchSelected',
    'switchPicked',
    'photo',
    'checkmarkIcon',
    'pickedIcon',
    'filterOptions',
    'submit',
    'counter',
    'filterToggleButton'
  ];

  connect(e) {
    this.handleOnlySelectedOption();
    this.handleHidePickedOption();
    this.handlePhotosRendering();
    this.handleSubmitDisable();
  }

  // hide images initially until all images are loaded
  // prevent checkmarks being rendered before all images are loaded
  handlePhotosRendering() {
    const checkmarks = this.checkmarkIconTargets;
    const pickedIcons = this.pickedIconTargets;
    const photos = this.photoTargets;

    photos.forEach((photo) => {
      photo.classList.add('skeleton-background');
      // hide image initially
      photo.querySelector('.b2b-photo-selection__item-content').style.display = 'none';
    });

    // show images
    photos.forEach((photo) => {
      photo.classList.remove('skeleton-background');
      photo.querySelector('.b2b-photo-selection__item-content').style.display = 'flex';

      // Add information that will displayed on mouseover
      // with already picked images
      if (photo.children[0].disabled) {
        photo.title = 'Foto wurde bereits ausgesucht';
      }
    });

    // Show selected checkmarks
    checkmarks.forEach((item) => {
      item.style.visibility = 'visible';
      item.style.opacity = 1;
    });

    // Show downloaded icon
    pickedIcons.forEach((item) => {
      item.style.visibility = 'visible';
      item.style.opacity = 1;
    });
  }

  // layout switcher options
  setGridLayout() {
    this.gridTargets.forEach((grid) => {
      grid.setAttribute('data-variant', 'grid');
    });
  }

  setStackedLayout() {
    this.gridTargets.forEach((grid) => {
      grid.setAttribute('data-variant', 'stacked');
    });
  }

  /**
   * Get Helper for Grid Mode View
   */
  gridMode() {
    return this.gridTarget.dataset.show;
  }

  /**
   * Set Helper for Grid Mode View
   */
  setGridMode(mode) {
    this.gridTarget.setAttribute('data-show', mode);
  }

  /**
   * Get Helper for Grid Selection Mode
   */
  selectionMode() {
    return this.element.dataset.selectionMode;
  }

  toggleOnlySelected() {
    if (this.gridMode() === 'all') {
      this.setGridMode('only-selected');
      window.dispatchEvent(new CustomEvent('infiniteScrollStopLoadingMore'));
      this.photoTargets.forEach((photo) => {
        if (photo.children[0].checked === false) {
          photo.classList.add('hidden');
        }
      });
    } else if (this.gridMode() === 'hide-picked') {
      this.setGridMode('only-selected-without-picked');
      window.dispatchEvent(new CustomEvent('infiniteScrollStopLoadingMore'));
      this.photoTargets.forEach((photo) => {
        if (photo.children[0].checked === false) {
          photo.classList.add('hidden');
        }
      });
    } else if (this.gridMode() === 'only-selected-without-picked') {
      this.setGridMode('hide-picked');
      window.dispatchEvent(new CustomEvent('infiniteScrollResumeLoadingMore'));
      this.photoTargets.forEach((photo) => {
        if (photo.children[0].checked === false) {
          photo.classList.remove('hidden');
        }
        if (photo.children[0].disabled) {
          photo.classList.add('hidden');
        }
      });
    } else {
      this.setGridMode('all');
      window.dispatchEvent(new CustomEvent('infiniteScrollResumeLoadingMore'));
      this.photoTargets.forEach((photo) => {
        if (photo.children[0].checked === false || photo.children[0].disabled) {
          photo.classList.remove('hidden');
        }
      });
    }
  }

  hidePicked() {
    if (this.gridMode() === 'all') {
      this.setGridMode('hide-picked');
      this.photoTargets.forEach((photo) => {
        if (photo.children[0].disabled) {
          photo.classList.add('hidden');
        }
      });
    } else if (this.gridMode() === 'only-selected') {
      this.setGridMode('only-selected-without-picked');
      this.photoTargets.forEach((photo) => {
        if (photo.children[0].disabled) {
          photo.classList.add('hidden');
        }
      });
    } else if (this.gridMode() === 'only-selected-without-picked') {
      this.setGridMode('only-selected');
      this.photoTargets.forEach((photo) => {
        if (photo.children[0].disabled && photo.children[0].checked === true) {
          photo.classList.remove('hidden');
        }
      });
    } else {
      this.setGridMode('all');
      this.photoTargets.forEach((photo) => {
        if (photo.children[0].disabled || photo.children[0].checked === false) {
          photo.classList.remove('hidden');
        }
      });
    }
  }

  // handle toggle-switch "hide-picked" on connect
  handleHidePickedOption() {
    if (
      this.gridMode() === 'hide-picked' ||
      this.gridMode() === 'only-selected-without-picked'
    ) {
      this.switchPickedTarget.checked = true;

      // hide all not-selected photos
      this.photoTargets.forEach((photo) => {
        if (photo.children[0].disabled) {
          photo.classList.add('hidden');
        }
      });
    } else {
      this.switchPickedTarget.checked = false;
    }
  }

  // handle toggle-switch "only-selected" on connect
  handleOnlySelectedOption() {
    if (this.gridMode() === 'only-selected') {
      this.switchSelectedTarget.checked = true;

      // hide all not-selected photos
      this.photoTargets.forEach((photo) => {
        if (photo.children[0].checked === false) {
          photo.classList.add('hidden');
        }
      });
    } else {
      this.switchSelectedTarget.checked = false;
    }
  }

  toggleFilters() {
    if (this.filterOptionsTarget.dataset.show === 'closed') {
      this.filterOptionsTarget.setAttribute('data-show', 'open');
    } else {
      this.filterOptionsTarget.setAttribute('data-show', 'closed');
    }
  }

  // handle photo in "selected" modes: Click will uncheck the hidden
  // input and hide it from visible items
  handleNotSelected(e) {
    if (
      this.gridMode() === 'only-selected' ||
      this.gridMode() === 'only-selected-without-picked'
    ) {
      // Get target on which the event listener is installed
      const currentTarget = e.currentTarget;
      // add css animation that will fade-out the to be hidden photo
      currentTarget.classList.add('hide-animation');

      setTimeout(() => {
        this.photoTargets.forEach((photo) => {
          if (photo.classList.contains('hide-animation')) {
            photo.classList.remove('hide-animation');
          }
        });
        currentTarget.classList.add('hidden');
      }, 150);
    }
  }

  handleSubmitDisable() {
    this.submitTarget.disabled = !this.photoTargets.some(
      (photo) => photo.children[0].checked
    );
  }
}
