import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="form-update-partial"
export default class extends Controller {
  static targets = ['input'];
  static values = {
    url: String
  };

  updateFormPartial(e) {
    this.inputTarget.form.action = this.urlValue;
    this.inputTarget.form.setAttribute('novalidate', 'true');
    this.inputTarget.form.requestSubmit();
  }
}
