import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field'];
  static debounces = ['search'];

  async connect() {
    const { useDebounce } = await import('stimulus-use');
    useDebounce(this, { wait: 500 });
    document.addEventListener('turbo:submit-end', () => {
      const loadingDot = document.querySelector('.loading-dot');

      if (loadingDot) {
        loadingDot.classList.add('visually-hidden');
      }
    });
  }

  search() {
    const loadingDot = document.querySelector('.loading-dot');

    if (typeof Turbo != 'undefined') {
      this.fieldTarget.form.requestSubmit();
      if (loadingDot) {
        loadingDot.classList.remove('visually-hidden');
      }
    }
  }
}
