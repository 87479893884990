import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'inStudioRadio',
    'offStudioRadio',

    'inStudioSelect',
    'offStudioSelect',

    'existingCustomerRadio',
    'newCustomerRadio',
    'guestCustomerRadio',

    'existingCustomerSelect',
    'newCustomerInfo',
    'newGuestInfo'
  ];

  toggle(element, visibleCondition = true) {
    element?.classList.toggle('hidden', !visibleCondition);
  }

  onInOrOffStudioSelect(event) {
    // possible values: in_studio, off_studio
    const { value } = event.target;

    this.toggle(this.offStudioSelectTarget, value === 'off_studio');
  }

  onCustomerTypeSelect(event) {
    // possible values: existing, new, guest
    const { value } = event.target;

    this.toggle(this.newCustomerInfoTarget, value === 'new');
    this.toggle(this.existingCustomerSelectTarget, value === 'existing');
    this.toggle(this.newGuestInfoTarget, value === 'guest');
  }
}
