import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const calendar = document.querySelector('.calendar');
    const dayButtons = Array.from(
      calendar.querySelectorAll('.calendar__cell-button[data-date]')
    );

    const availableDateEls = Array.from(
      document.querySelectorAll('.calendar__available-times')
    );
    const availableTimes = availableDateEls.map((day) => day.dataset.date).sort(); // Format: ['2022-02-11', '2022-02-15', ...]

    // select the first available date and show its times
    const firstAvailableDate = dayButtons.find(
      (button) => button.dataset.date === availableTimes[0]
    );

    this.selectDate(firstAvailableDate);
    availableDateEls[0].removeAttribute('hidden');

    // add event listeners to the calendar buttons
    dayButtons?.forEach((button) => {
      if (availableTimes.includes(button.dataset.date)) {
        // handle selection of date
        button.addEventListener('click', () => {
          if (this.isSelected(button)) return;

          dayButtons.forEach((dayButton) => this.deselectDate(dayButton));
          this.selectDate(button);

          // show the times only for the selected date
          availableDateEls.forEach((times) =>
            times.toggleAttribute('hidden', times.dataset.date !== button.dataset.date)
          );
        });
      } else {
        // disable all days without times
        button.setAttribute('disabled', true);
      }
    });
  }

  selectDate(button) {
    button?.setAttribute('aria-selected', 'true');
  }

  deselectDate(button) {
    button.setAttribute('aria-selected', 'false');
  }

  isSelected(button) {
    return button.getAttribute('aria-selected') === 'true';
  }
}
