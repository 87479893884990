import { Controller } from '@hotwired/stimulus';
import SelectionArea from '@viselect/vanilla';

export default class extends Controller {
  connect() {
    this.addSelectionListener();
  }

  addSelectionListener = () => {
    this.selectionArea = new SelectionArea({
      selectables: ['.image-select'],
      boundaries: ['.image-select-wrapper']
    }).on(
      'move',
      ({
        event,
        store: {
          changed: { added, removed }
        }
      }) => {
        if (event === null) {
          return;
        }

        for (const el of added) {
          el.children[0].checked = true;
        }

        for (const el of removed) {
          el.children[0].checked = false;
        }
      }
    );
  };

  disconnect() {
    if (this.selectionArea) {
      this.selectionArea.destroy();
    }
  }
}
