import Sortable from 'sortablejs';
import { Controller } from '@hotwired/stimulus';
import { patch } from '@rails/request.js';

export default class extends Controller {
  static targets = ['row', 'sortable', 'dragable'];

  connect() {
    if (this.hasSortableTarget) {
      new Sortable(this.sortableTarget, {
        draggable: '.dragable',
        onEnd: this.end
      });
    }

    this.rowTargets.forEach((row) => {
      row.setAttribute('tabindex', 0);

      row.addEventListener('click', function () {
        Turbo.visit(this.getAttribute('data-href'));
      });

      // add keyboard support
      row.addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {
          Turbo.visit(this.getAttribute('data-href'));
        }
      });
    });
  }

  async end({ item, newIndex }) {
    if (!item.dataset.sortableUpdateUrl) return;

    const data = new FormData();
    data.append('sort-index', newIndex + 1);

    await patch(item.dataset.sortableUpdateUrl, {
      body: data,
      responseKind: 'turbo-stream'
    });
  }
}
