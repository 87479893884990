import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    path: String
  };

  connect() {}

  category_selected(event) {
    Turbo.visit(`${this.pathValue}?shooting_category=${event.target.value}`, {
      action: 'replace'
    });
  }
}
