import { Controller } from '@hotwired/stimulus';

const locationColor = '#CE4053';

export default class extends Controller {
  static targets = ['longitude', 'latitude'];
  static values = {
    apiKey: String
  };

  async connect() {
    await this.initMapbox();
    // if the values are filled this would update the map on initialization
    this.updateMarker();
    window.addEventListener('autocompletePlacesClear', () => this.clearMarker());
    window.addEventListener('autocompletePlacesResult', () => this.updateMarker());
  }

  clearMarker() {
    this.map();
    this.marker().remove();
  }

  updateMarker() {
    if (this.longitudeTarget.value !== '' && this.latitudeTarget.value !== '') {
      this.marker()
        .setLngLat([
          parseFloat(this.longitudeTarget.value),
          parseFloat(this.latitudeTarget.value)
        ])
        .addTo(this.map());
      this.showMap();
      this.map().jumpTo({ center: this.marker().getLngLat(), zoom: 15 });
    }
  }

  async initMapbox() {
    if (window.mapboxGl == undefined) {
      window.mapboxGl = await import('mapbox-gl');
    }
    this.map();
  }

  showMap() {
    document.getElementById('map').style.display = 'block';
    this.map().resize();
  }

  marker() {
    if (this._marker === undefined) {
      this._marker = new window.mapboxGl.Marker({ color: locationColor });
    }
    return this._marker;
  }

  map() {
    if (this._map === undefined) {
      this._map = new window.mapboxGl.Map({
        accessToken: this.apiKeyValue,
        container: 'map', // container ID
        style: 'mapbox://styles/mapbox/streets-v11', // style URL
        center: [7.2170018613717595, 51.47822920527737], // starting position [lng, lat]
        zoom: 9, // starting zoom
        scrollZoom: false,
        dragPan: false
      });
    }
    return this._map;
  }
}
