import SortableGroupsController from './sortable_groups_controller.js';

export default class extends SortableGroupsController {
  connect() {
    super.connect();
  }

  disconnect() {}

  moveTo(event) {
    const el = event.target.closest('.staff-page-elements__item');
    const newIndex = JSON.parse(event.target.value) - 1;

    this.end({ ...event, item: el, newIndex: newIndex, to: this.element });
  }

  end(event) {
    super.end(event);
  }
}
