import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menu', 'MainNav', 'SubNav'];
  static values = { open: Boolean };

  async connect() {
    const { useClickOutside } = await import('stimulus-use');
    useClickOutside(this, { element: this.menuTarget });
  }

  handleKeydown(event) {
    event = event || window.event;
    let isEscape = false;

    if ('key' in event) {
      isEscape = event.key === 'Escape' || event.key === 'Esc';
    }

    if (isEscape) {
      this.close();
    }
  }

  get isPreview() {
    return document.documentElement.hasAttribute('data-turbo-preview');
  }

  mainNavAnimation() {
    if (this.MainNavTarget.classList.contains('slideInLeft')) {
      this.MainNavTarget.classList.remove('slideInLeft');
      this.MainNavTarget.classList.add('slideOutLeft');
    } else if (this.MainNavTarget.classList.contains('slideOutLeft')) {
      this.MainNavTarget.classList.remove('slideOutLeft');
      this.MainNavTarget.classList.add('slideInLeft');
    } else {
      this.MainNavTarget.classList.add('slideInLeft');
    }
  }

  subNavAnimation() {
    if (this.SubNavTarget.classList.contains('slideInLeft')) {
      this.SubNavTarget.classList.remove('slideInLeft');
      this.SubNavTarget.classList.add('slideOutLeft');
    } else if (this.SubNavTarget.classList.contains('slideOutLeft')) {
      this.SubNavTarget.classList.remove('slideOutLeft');
      this.SubNavTarget.classList.add('slideInLeft');
    } else {
      this.SubNavTarget.classList.add('slideInLeft');
    }
  }

  openValueChanged() {
    if (this.openValue == true && !this.isPreview) {
      this.element.setAttribute('style', 'display: block;');
      this.MainNavTarget.setAttribute('style', 'display: block;');
      this.element.setAttribute('aria-expanded', 'true');
      document.body.classList.add('noscroll');
      document.body.insertAdjacentHTML(
        'beforeend',
        '<div class="background-overlay fadeIn"></div>'
      );
      this.mainNavAnimation();
      if (this.hasSubNavTarget) {
        setTimeout(() => {
          this.SubNavTarget.setAttribute('style', 'display: block;');
          this.subNavAnimation();
        }, 300);
      }
    } else if (
      this.openValue == false &&
      this.MainNavTarget.classList.contains('slideInLeft') &&
      !this.isPreview
    ) {
      if (this.hasSubNavTarget) {
        this.subNavAnimation();
        setTimeout(() => {
          this.mainNavAnimation();
        }, 500);
      } else {
        this.mainNavAnimation();
      }
      this.element.setAttribute('aria-expanded', 'false');
      document.getElementsByClassName('background-overlay')[0].classList.remove('fadeIn');
      document.getElementsByClassName('background-overlay')[0].classList.add('fadeOut');
      setTimeout(() => {
        document.getElementsByClassName('background-overlay')[0].remove();
      }, 400);
      document.body.classList.remove('noscroll');
    }
  }

  open(e) {
    this.openValue = e.detail.open;

    this.MainNavTarget.classList.add('animated');
    if (this.hasSubNavTarget) {
      this.SubNavTarget.classList.add('animated');
    }
  }

  clickOutside(event) {
    if (this.openValue && event.target.id != 'navigation-modal-button') {
      this.close();
    }
  }

  close() {
    this.openValue = false;
  }

  disconnect() {
    if (this.hasMainNavTaget) {
      this.MainNavTarget.removeAttribute('style');
      this.MainNavTarget.classList.remove('slideInLeft');
    }
    if (this.hasSubNavTarget) {
      this.SubNavTarget.removeAttribute('style');
      this.SubNavTarget.classList.remove('slideInLeft');
    }
    if (document.getElementsByClassName('background-overlay')[0] == undefined) {
      return;
    } else {
      document.getElementsByClassName('background-overlay')[0].remove();
    }
  }
}
