import { Controller } from '@hotwired/stimulus';
import { patch } from '../api';

export default class extends Controller {
  static targets = ['calendar', 'container', 'draggableEl'];

  static values = {
    newOrderUrl: String,
    shootingsUrl: String,
    appointmentsUrl: String,
    businessHoursUrl: String,
    locale: String,
    newAppointmentUrl: String,
    selectable: Boolean
  };
  debounceTimer = null;

  async connect() {
    const { makeCalendar } = await import('../calendar_controller_helper');
    this.calendar = makeCalendar({
      locale: this.localeValue,
      calendarTarget: this.calendarTarget,
      draggableElements: this.draggableElTargets,
      eventSources: [
        { id: 'shootingsEventSource', url: this.shootingsUrlValue },
        {
          id: 'appointmentsEventSource',
          url: this.appointmentsUrlValue,
          color: '#d88737',
          editable: true
        },
        {
          id: 'businessHoursEventSource',
          url: this.businessHoursUrlValue,
          color: '#ff0000',
          editable: false,
          display: 'background'
        }
      ],
      eventStartEditable: true,
      eventDrop: this.eventDrop,
      drop: this.drop,
      eventContent: this.eventContent,
      eventMouseEnter: this.eventMouseEnter,
      eventMouseLeave: this.eventMouseLeave,
      leftHeader: 'prev,next today,fullscreen,appointments',
      customButtons: this.customButtons,
      selectable: this.selectableValue,
      select: this.select,
      eventClick: this.eventClick,
      eventResize: this.eventDrop
    });
    this.calendar.render();
    const now = new Date();
    this.calendar.scrollToTime({ hour: now.getHours() });
  }

  eventClick = (info) => {
    info.jsEvent.preventDefault();
    switch (info.event.source.id) {
      case 'appointmentsEventSource':
        document.getElementById('modal').setAttribute('src', info.event.url);
        break;
      case 'shootingsEventSource':
        window.open(info.event.url);
    }
  };

  eventDrop = async (eventDropInfo) => {
    if (
      eventDropInfo.event.start === eventDropInfo.oldEvent.start &&
      eventDropInfo.event.end === eventDropInfo.oldEvent.end
    )
      return;
    try {
      if (!confirm(window.I18n.confirmMoveEvent)) {
        throw new Error();
      }
      const eventSource = eventDropInfo.event.source;
      const requestBody =
        eventSource.id === 'appointmentsEventSource'
          ? {
              appointment: {
                starts_at: eventDropInfo.event.startStr.substring(0, 19),
                stops_at: eventDropInfo.event.endStr.substring(0, 19)
              }
            }
          : {
              shootings_update_shooting_form: {
                ...eventDropInfo.event.extendedProps.formAttributes,
                starts_at: eventDropInfo.event.startStr.substring(0, 19)
              }
            };
      const newEvent = await patch(
        eventDropInfo.event.extendedProps.updateUrl,
        requestBody
      );
      eventDropInfo.event.remove();
      this.calendar.addEvent(newEvent, eventSource.id);
      this.calendar.refetchEvents();
    } catch (error) {
      eventDropInfo.revert();
    }
  };

  drop = (dropInfo) => {
    this.createNewShootingEvent(
      JSON.parse(dropInfo.draggedEl.dataset.event),
      dropInfo.dateStr,
      dropInfo.draggedEl
    );
  };

  createNewShootingEvent(event, start, draggedEl) {
    this.newShootingEvent = this.calendar.addEvent({
      ...event,
      start: Date.parse(start),
      end: Date.parse(start) + event.duration
    });

    const url = new URL(this.newOrderUrlValue);
    url.searchParams.set('starts_at', start);
    url.searchParams.set('shooting_category_id', event.shooting_category_id);
    url.searchParams.set('shooting_variant_id', event.shooting_variant_id);

    document.getElementById('modal').setAttribute('src', url);

    draggedEl.remove();
  }

  eventMouseEnter = (mouseEnterInfo) => {
    const el = mouseEnterInfo.el;
    const event = mouseEnterInfo.event;
    this.debounce(() => {
      el.parentNode.classList.add('calendar-zoom');
      if (event.extendedProps.eventColor) {
        el.style.background = event.extendedProps.eventColor;
      }
    }, 750)();
  };

  eventMouseLeave = (mouseLeaveInfo) => {
    const el = mouseLeaveInfo.el;
    const event = mouseLeaveInfo.event;
    clearTimeout(this.debounceTimer);
    el.parentNode.classList.remove('calendar-zoom');
    if (event.extendedProps.background) {
      el.style.background = event.extendedProps.background;
    }
  };

  eventContent = ({ event }, createElement) => {
    let text = createElement('p', {}, event.title);
    let title = createElement('div', { class: 'staff-calendar__head' }, text);
    let startStopTime = createElement(
      'h4',
      {},
      `${event.start.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      })} - ${event.end.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      })}`
    );
    let wrapper = createElement('div', { class: 'fc-event-main-frame staff-calendar' }, [
      startStopTime,
      title
    ]);

    return wrapper;
  };

  select = (selectionInfo) => {
    const url = new URL(this.newAppointmentUrlValue);
    url.searchParams.set(
      'appointment_form[starts_at]',
      selectionInfo.startStr.substring(0, 19)
    );
    url.searchParams.set(
      'appointment_form[stops_at]',
      selectionInfo.endStr.substring(0, 19)
    );
    document.getElementById('modal').setAttribute('src', url);
  };

  toggleAppointments = () => {
    document.querySelectorAll('.appointment-event').forEach((event) => {
      event.classList.toggle('visually-hidden');
    });
  };

  toggleFullscreen = () => {
    if (!this.containerTarget.classList.contains('calendar__container--fullscreen')) {
      this.containerTarget.classList.add('calendar__container--fullscreen');
      this.calendar.setOption('height', '100%');
      return;
    }

    this.containerTarget.classList.remove('calendar__container--fullscreen');
    this.calendar.setOption('height', undefined);
  };

  customButtons = {
    appointments: {
      text: window.I18n.toggleAppointments,
      click: this.toggleAppointments
    },
    fullscreen: {
      text: window.I18n.toggleFullscreen,
      click: this.toggleFullscreen
    }
  };

  modalSuccess(_event) {
    this.calendar.getEventSourceById('appointmentsEventSource').refetch();
  }

  debounce(func, timeout) {
    return (...args) => {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
}
