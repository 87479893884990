import { Application } from '@hotwired/stimulus';
import { Autocomplete } from 'stimulus-autocomplete';
import { appsignal } from '../appsignal';
import { installErrorHandler } from '@appsignal/stimulus';

const application = Application.start();
application.register('autocomplete', Autocomplete);
installErrorHandler(appsignal, application);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

import CheckboxSelectAll from 'stimulus-checkbox-select-all';
application.register('checkbox-select-all', CheckboxSelectAll);

export { application };
