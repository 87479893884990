import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="kpis-live-refresh"
export default class extends Controller {
  static values = {
    enabled: { type: Boolean, default: false }
  };

  timeout;

  connect() {
    const fiveMinutes = 1000 * 60 * 5;
    if (this.enabledValue) {
      this.timeout = setTimeout(() => {
        window.location.reload();
      }, fiveMinutes);
    }
  }

  disconnect() {
    clearTimeout(this.timeout);
  }
}
