import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ['content', 'whitelist'];

  connect() {
    useClickOutside(this, { element: this.contentTarget });
  }

  submitEnd(event) {
    if (event.detail.success) {
      const successEvent = new CustomEvent('modal-submit-end-success', {
        detail: event.detail
      });
      document.dispatchEvent(successEvent);
    }
    this.close();
  }

  clickOutside(event) {
    if (event.target instanceof HTMLInputElement) {
      return;
    }
    if (event.target.hasAttribute('data-autocomplete-value')) {
      return;
    }

    this.close();
  }

  close() {
    this.element.remove();
  }
}
