import { Controller } from '@hotwired/stimulus';
// import { useIdle } from 'stimulus-use';
import { getMetaValue } from '../get_meta_value';

const events = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'];
const awayTimeout = 2 * 60 * 1_000;
const logoutTimeout = 10 * 60 * 1_000;

export default class extends Controller {
  static values = {
    upholdUrl: String,
    logoutUrl: String
  };
  static targets = ['warning'];

  connect() {
    this.resetTimers();
    this.observe();
  }

  onEvent = (_event) => {
    if (this.isIdle) {
      this.back();
    }
    this.resetTimers();
  };

  onVisibility = (event) => {
    if (!document.hidden) this.onEvent(event);
  };

  resetTimers() {
    this.isIdle = false;
    this.clearTimers();
    this.startTimers();
  }

  clearTimers() {
    clearTimeout(this.logoutTimer);
    clearTimeout(this.awayTimer);
  }

  startTimers() {
    this.awayTimer = setTimeout(() => {
      this.isIdle = true;
      this.away();
    }, awayTimeout);
    this.logoutTimer = setTimeout(() => {
      this.logout();
    }, logoutTimeout);
  }

  away() {
    this.warningTarget.style.display = 'block';
  }

  back() {
    this.warningTarget.style.display = 'none';
    this.uphold();
  }

  observe() {
    events.forEach((event) => {
      window.addEventListener(event, this.onEvent);
    });
    document.addEventListener('visibilitychange', this.onVisibility);
  }

  unobserve() {
    events.forEach((event) => {
      window.removeEventListener(event, this.onEvent);
    });
    document.removeEventListener('visibilitychange', this.onVisibility);
  }

  disconnect() {
    this.clearTimers();
    this.unobserve();
  }

  uphold() {
    fetch(this.upholdUrlValue);
  }

  logout() {
    const lastUrl = window.location.href;
    fetch(this.logoutUrlValue, {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'X-CSRF-Token': getMetaValue('csrf-token')
      }
    }).then((_response) => {
      window.location.href = lastUrl;
    });
  }
}
