import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['animate', 'startPulse'];
  static classes = ['animate'];
  disableCartAnimation;

  connect() {
    this.disableCartAnimation = !!localStorage.getItem('disable-cart-animation');
    if (this.hasStartPulseTarget) {
      this.toggleAnimation();
    }
  }

  toggleAnimation() {
    if (this.disableCartAnimation) {
      this.animateTarget.classList.remove(this.animateClass);
    } else {
      this.animateTarget.classList.add(this.animateClass);
    }
  }

  disableAnimation() {
    localStorage.setItem('disable-cart-animation', true);
    this.disableCartAnimation = true;
    this.toggleAnimation();
  }
}
