import { Autocomplete } from 'stimulus-autocomplete';

export default class extends Autocomplete {
  static targets = ['firstNameInput', 'lastNameInput', 'phoneInput'];

  connect() {
    // console.log(this.firstNameInputTarget);
    super.connect();
  }

  commit(selected) {
    const firstNameValue = selected.getAttribute('data-autocomplete-first-name-value');
    const lastNameValue = selected.getAttribute('data-autocomplete-last-name-value');
    const phoneValue = selected.getAttribute('data-autocomplete-phone-value');

    this.firstNameInputTarget.value = firstNameValue;
    this.lastNameInputTarget.value = lastNameValue;
    this.phoneInputTarget.value = phoneValue;

    return super.commit(selected);
  }
}
