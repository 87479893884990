import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    window.addEventListener('pageshow', function (event) {
      var historyTraversal =
        event.persisted ||
        (typeof window.performance != 'undefined' &&
          window.performance.navigation.type === 2);
      if (historyTraversal) {
        // Handle page restore.
        window.location.reload();
      }
    });
  }
}
