import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'content'];
  static values = { open: Boolean };

  openValueChanged() {
    if (this.openValue == true) {
      this.contentTarget.classList.remove('visually-hidden');
      this.element.setAttribute('aria-expanded', 'true');
      this.buttonTarget.innerHTML = '-';
      this.buttonTarget.setAttribute('data-variant', 'tree-open');
    } else {
      this.contentTarget.classList.add('visually-hidden');
      this.element.setAttribute('aria-expanded', 'false');
      this.buttonTarget.innerHTML = '+';
      this.buttonTarget.setAttribute('data-variant', 'tree-closed');
    }
  }

  open() {
    if (this.openValue == false) {
      this.openValue = true;
    } else {
      this.close();
    }
  }

  close() {
    this.openValue = false;
  }

  disconnect() {}
}
