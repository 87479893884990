import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ['modal', 'outside', 'open'];

  connect() {
    if (this.hasOutsideTarget) useClickOutside(this, { element: this.outsideTarget });
    if (this.hasModalTarget) this.modalTarget.style.display = 'none';
  }

  open() {
    this.modalTarget.style.display = 'flex';
  }

  clickOutside(event) {
    if (this.openTarget.contains(event.target)) {
      return;
    }
    this.close();
  }

  close() {
    this.modalTarget.style.display = 'none';
  }
}
