import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['toggle', 'hide', 'arrow'];
  connect() {
    if (!this.hasToggleTarget) return;
    this.toggleTarget.addEventListener('click', () => {
      this.toggleTarget.classList.toggle('open');
      this.hideTargets.forEach((target) => {
        target.classList.toggle('visually-hidden');
      });
    });
  }
}
