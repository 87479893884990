import { Controller } from '@hotwired/stimulus';
import * as turf from '@turf/turf';

const locationColor = '#CE4053';

export default class extends Controller {
  static targets = ['longitude', 'latitude', 'radius'];
  static values = {
    apiKey: String
  };

  async connect() {
    this.initMapbox();
    this.updateMarker();
    window.addEventListener('autocompletePlacesClear', () => this.clearMarker());
    window.addEventListener('autocompletePlacesResult', () => this.updateMarker());
  }

  clearMarker() {
    this.map();
    this.marker().remove();
  }

  updateMarker() {
    if (this.longitudeTarget.value !== '' && this.latitudeTarget.value !== '') {
      this.marker()
        .setLngLat([
          parseFloat(this.longitudeTarget.value),
          parseFloat(this.latitudeTarget.value)
        ])
        .addTo(this.map());
      this.showMap();
      this.map().jumpTo({ center: this.marker().getLngLat(), zoom: 15 });

      // mapbox takes a while to draw so we take a little timeout and draw the radius circle afterwards
      setTimeout(() => this.circle(), 1000);
    }
  }

  async initMapbox() {
    if (window.mapboxGl == undefined) {
      window.mapboxGl = await import('mapbox-gl');
    }
    this.map();
  }

  showMap() {
    document.getElementById('map').style.display = 'block';
    this.map().resize();
  }

  marker() {
    if (this._marker === undefined) {
      this._marker = new window.mapboxGl.Marker({ color: locationColor });
    }
    return this._marker;
  }

  map() {
    if (this._map === undefined) {
      this._map = new window.mapboxGl.Map({
        accessToken: this.apiKeyValue,
        container: 'map', // container ID
        style: 'mapbox://styles/mapbox/streets-v11', // style URL
        center: [7.2170018613717595, 51.47822920527737], // starting position [lng, lat]
        zoom: 9, // starting zoom
        scrollZoom: false,
        dragPan: false
      });
    }
    return this._map;
  }

  radius() {
    if (this.radiusTargets.length > 1) {
      return this.radiusTargets.find((el) => el.checked).value;
    } else {
      return this.radiusTarget.value;
    }
  }

  circle() {
    if (
      this.longitudeTarget.value === '' ||
      this.latitudeTarget.value === '' ||
      this.radiusTarget.value === ''
    ) {
      return;
    }
    const [lat, lng] = [
      parseFloat(this.longitudeTarget.value),
      parseFloat(this.latitudeTarget.value)
    ];
    const center = [lat, lng];
    const options = {
      steps: 20,
      units: 'kilometers'
    };

    const circle = turf.circle(center, this.radius(), options);

    try {
      if (this._map.getLayer('circle-fill')) {
        this._map.removeLayer('circle-fill');
        this._map.removeSource('circleData');
      }
    } catch (error) {}

    this._map.addSource('circleData', {
      type: 'geojson',
      data: circle
    });

    this._map.addLayer({
      id: 'circle-fill',
      type: 'fill',
      source: 'circleData',
      paint: {
        'fill-color': 'yellow',
        'fill-opacity': 0.2,
        'fill-outline-color': 'black'
      }
    });

    this.fitToCircle(center, this.radius());
  }

  fitToCircle(center, radius) {
    var buffered = turf.buffer(turf.point(center), radius, {
      units: 'kilometers'
    });
    var bbox = turf.bbox(buffered);
    this._map.fitBounds([
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]]
    ]);
  }
}
