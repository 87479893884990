import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  async connect() {
    const { useDispatch } = await import('stimulus-use');
    useDispatch(this);
  }

  openNavigation() {
    this.dispatch('openNavigation', { open: true });
  }
}
