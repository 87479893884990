import { Controller } from '@hotwired/stimulus';
import Dropzone from 'dropzone';

export default class extends Controller {
  static targets = ['info', 'counter'];
  static values = {
    url: String,
    deleteUrl: String,
    elementId: String
  };

  connect() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes[
      'content'
    ].value;
    const url = this.urlValue;
    const dropzoneElementId = this.hasElementIdValue
      ? this.elementIdValue
      : '#dropzone-target';
    const dropzone = new Dropzone(dropzoneElementId, {
      url,
      addRemoveLinks: this.hasDeleteUrlValue,
      headers: {
        'X-CSRF-Token': csrfToken
      },
      acceptedFiles:
        'image/jpeg,image/webp,image/png,impage/apng,image/avif,image/gif,image/tiff'
    });
    dropzone.on('addedfile', (file) => {
      this.updateFileCounter((v) => v + 1);
      if (this.hasInfoTarget) {
        this.infoTarget.style.display = 'flex';
      }
    });

    dropzone.on('removedfile', (file) => {
      const erroredFile = dropzone.files.find((file) => file.status == 'error');
      if (erroredFile && this.counterTarget.value > 0) {
        const errorMessageElement =
          erroredFile.previewElement.querySelector('.dz-error-message');
        errorMessageElement.remove();
        const errorMarkElement =
          erroredFile.previewElement.querySelector('.dz-error-mark');
        errorMarkElement.remove();

        erroredFile.status = 'queued';
        dropzone.processQueue();
      }

      this.updateFileCounter((v) => v - 1);

      const fileId = file.previewElement.dataset.fileId;
      const url = new URL(this.deleteUrlValue);

      fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify({ file_id: fileId }) // body data type must match "Content-Type" header
      });
    });
    dropzone.on('success', (file, response) => {
      file.previewElement.dataset.fileId = response.fileId;
    });
  }

  updateFileCounter(operation) {
    if (this.hasCounterTarget) {
      this.counterTarget.value = operation(Number.parseInt(this.counterTarget.value));
      const event = new Event('change');
      this.counterTarget.dispatchEvent(event);
    }
  }
}
