import { Controller } from '@hotwired/stimulus';
import Tagify from '@yaireo/tagify';

export default class extends Controller {
  static targets = ['input'];
  static values = { existing: Array };

  connect() {
    new Tagify(this.inputTarget, {
      whitelist: this.existingValue,
      originalInputValueFormat: (valuesArr) =>
        valuesArr.map((item) => item.value).join(',')
    });
  }
}
