import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:submit-end', (event) => {
      if (event.target != this.element) return;
      setTimeout(() => {
        const successIndicator = document.querySelector(`#${event.target.id}_success`);
        const errorIndicator = document.querySelector(`#${event.target.id}_error`);

        successIndicator.classList.add('input__success--hidden');
        errorIndicator.classList.add('input__error--hidden');
      }, 2000);
    });
  }
}
