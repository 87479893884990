import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { numberInclusiveDownloads: Number, alreadyPickedPhotoIds: Array };
  static targets = ['photo', 'availableCounter'];

  connect() {
    this.handleAvailableCount();
    this.handleDisableWhenAllPicked();
  }

  availableCount() {
    const count = this.photoTargets.filter((photo) => photo.children[0].checked).length;
    return this.numberInclusiveDownloadsValue - count;
  }

  handleDisableWhenAllPicked() {
    const availableCount = this.availableCount();

    this.photoTargets.forEach((photo) => {
      photo.children[0].disabled =
        (availableCount === 0 && !photo.children[0].checked) ||
        this.alreadyPickedPhotoIdsValue.includes(photo.id);
      photo.children[2].style.visibility =
        availableCount === 0 &&
        !photo.children[0].checked &&
        !this.alreadyPickedPhotoIdsValue.includes(photo.id)
          ? 'hidden'
          : 'visible';
    });
  }

  handleAvailableCount() {
    const availableCount = this.availableCount();

    switch (availableCount) {
      case 0:
        this.availableCounterTarget.innerHTML = `Du hast alle Fotos ausgesucht.`;
        break;
      case 1:
        this.availableCounterTarget.innerHTML = `Du kannst dir noch 1 Foto aussuchen.`;
        break;
      default:
        this.availableCounterTarget.innerHTML = `Du kannst dir noch ${availableCount} Fotos aussuchen.`;
        break;
    }
  }
}
